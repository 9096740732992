import React, { useEffect, useState } from "react"
import CreditCard from "../Popups/creditCard"
import CompletePayment from "./completePayment"
import AxiosInstance from "../../../services/axiosInstance"

const PaymentMethod = ({ states, setStates, due, payor }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
  const [showCompletePaymentModal, setShowCompletePaymentModal] = useState(false)

  const [onfilePayments, setOnfilePayments] = useState([
    // { ending: "5432" }, { ending: "1234" }
  ])
  const [selectedOnfilePayment, setSelectedOnfilepayment] = useState({})
  const [incomingList, setIncomingList] = useState([])

  const udpateChargeOrPayment = e => {
    setStates({ ...states, chargeOrPayment: e.target.value })
  }

  const selectPaymentMethod = e => {
    console.log(e, e.target.checked, e.target.value)
  }

  const updateAcceptedPaymentMethods = e => {
    let localPayMethods = [...states.payMethods]
    if (e.target.checked && !localPayMethods.includes(e.target.value)) localPayMethods.push(e.target.value)
    if (!e.target.checked && localPayMethods.includes(e.target.value))
      localPayMethods = localPayMethods.filter(payMethod => payMethod !== e.target.value)
    setStates({ ...states, payMethods: localPayMethods })
  }

  const updateNotes = e => {
    setStates({ ...states, notes: e.target.value })
  }

  const updateFooterNote = e => {
    setStates({ ...states, footerNote: e.target.value })
  }

  const updateSelectedOnfilePayment = onfilePayment => {
    setSelectedOnfilepayment(onfilePayment)
  }

  const createCreditCard = () => {
    setStates({ ...states, showCreditCardModal: true })
  }

  const getIncomingList = () => {
    AxiosInstance.get(`/setting-secure/list/incoming`).then(response => {
      const uniquePaymentMethod = [...new Set(response.data.items.map(q => q.type))]
      setIncomingList(uniquePaymentMethod)
    })
  }

  useEffect(() => {
    getIncomingList()
  }, [])

  return (
    <>
      <form name="invoicePayAndNotes" id="invoicePayAndNotes">
        <div className="row my-3">
          <div className="col-lg-12 text-center">
            <div className="form-check-inline">
              <input
                type="radio"
                name="charge_or_payment"
                onChange={udpateChargeOrPayment}
                id="charge_or_payment_charge"
                value="Charge"
                checked={states.chargeOrPayment === "Charge"}
                className="css-checkbox"
              />
              <label htmlFor="charge_or_payment_charge" className="css-radio-label radGroup2">
                Send for Payment
              </label>
              &nbsp;&nbsp;
              <input
                type="radio"
                name="charge_or_payment"
                onChange={udpateChargeOrPayment}
                id="charge_or_payment_methods"
                value="Payment Methods"
                className="css-checkbox"
                checked={states.chargeOrPayment === "Payment Methods"}
              />
              <label htmlFor="charge_or_payment_methods" className="css-radio-label radGroup2">
                Use Payment Method on File
              </label>
            </div>
          </div>
        </div>

        {states.chargeOrPayment === "Payment Methods" && (
          <div className="row row-cols-sm-auto g-2 align-items-center justify-content-center chargeContainerRow chargeContainer">
            <div className="col-12 mt-0">
              Charge <span className="paymentInstallation">${due.toFixed(2)}</span> to
            </div>

            <div className="col-12 mt-0">
              <div className="dropdown bootstrap-select me-2 dropup">
                <select
                  className="selectpicker me-2"
                  id="chargeDueNowAmoutOpts"
                  tabIndex="null"
                  onChange={selectPaymentMethod}
                  value={selectedPaymentMethod}
                  data-toggle="selectpicker"
                >
                  <option value="">=== Select ===</option>
                  {/* <option value="visa" data-icon="fa-cc-visa">
                    {" "}
                    ...4321
                  </option>
                  <option value="mastercard" data-icon="fa-cc-mastercard">
                    {" "}
                    ...5432
                  </option> */}
                  <option value="addPaymentMethod" data-icon="fa-plus">
                    Add Payment method
                  </option>
                </select>
                <button
                  type="button"
                  tabIndex="-1"
                  className="btn dropdown-toggle btn-secondary"
                  data-bs-toggle="dropdown"
                  role="combobox"
                  aria-owns="bs-select-1"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  title="Add Payment method"
                  data-id="chargeDueNowAmoutOpts"
                >
                  <div className="filter-option">
                    <div className="filter-option-inner">
                      <div className="filter-option-inner-inner">
                        <i className=" fa-plus"></i>&nbsp;
                        {Object.keys(selectedOnfilePayment).length === 0
                          ? "=== Select ==="
                          : `...${selectedOnfilePayment.ending}`}
                      </div>
                    </div>
                  </div>
                </button>
                <div className="dropdown-menu">
                  <div
                    className="inner show"
                    role="listbox"
                    id="bs-select-1"
                    tabIndex="-1"
                    aria-activedescendant="bs-select-1-3"
                  >
                    <ul className="dropdown-menu inner show" role="presentation">
                      <li
                        className={Object.keys(selectedOnfilePayment).length === 0 ? "selected active" : ""}
                      >
                        <a
                          role="option"
                          className={
                            Object.keys(selectedOnfilePayment).length === 0
                              ? "dropdown-item selected active"
                              : "dropdown-item"
                          }
                          tabIndex="0"
                          aria-setsize="4"
                          aria-posinset="1"
                        >
                          <span className="text">=== Select ===</span>
                        </a>
                      </li>
                      {onfilePayments.map((onfilePayment, index) => {
                        const className = onfilePayment === selectedOnfilePayment ? "selected active" : ""
                        const selectedOrNot = onfilePayment === selectedOnfilePayment ? "true" : "false"
                        return (
                          <li className={className} key={index}>
                            <a
                              onClick={() => updateSelectedOnfilePayment(onfilePayment)}
                              role="option"
                              className={`dropdown-item ${className}`}
                              aria-selected={selectedOrNot}
                              id="bs-select-1-1"
                              tabIndex="0"
                              aria-setsize="4"
                              aria-posinset={index + 1}
                            >
                              <span className="fab fa-cc-visa"></span>&nbsp;
                              <span className="text"> ...{onfilePayment.ending}</span>
                            </a>
                          </li>
                        )
                      })}

                      <li className="">
                        <a
                          onClick={createCreditCard}
                          role="option"
                          className="dropdown-item"
                          tabIndex="0"
                          aria-setsize="4"
                          aria-posinset={onfilePayments.length + 2}
                        >
                          <span className=" fa-plus"></span>&nbsp;
                          <span className="text">Add Payment method</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-0">
              {Object.keys(selectedOnfilePayment).length !== 0 && (
                <button
                  type="button"
                  className="btn btn-primary mt-2 mt-md-0 mb-0 btnCompletePaymentNow"
                  onClick={() => setShowCompletePaymentModal(true)}
                >
                  <i className="bi bi-check2"></i> Complete Payment Now
                </button>
              )}
            </div>
          </div>
        )}

        {states.chargeOrPayment === "Charge" && (
          <div className="row paymentMethodsContainer">
            <div className="col-lg-12 text-center">
              Payment Method(s) Accepted:
              {/*<input
                type="checkbox"
                name="payment_method[]"
                id="payment_method_ACH"
                className="css-checkbox"
                value="ACH"
                checked={states.payMethods.includes("ACH")}
                onChange={updateAcceptedPaymentMethods}
              />
              <label htmlFor="payment_method_ACH" className="css-checkbox-label mb-0">
                ACH e-check
              </label>{" "}
              &nbsp;&nbsp;
              <input
                type="checkbox"
                name="payment_method[]"
                id="payment_method_paypal"
                className="css-checkbox"
                value="PayPal"
                checked={states.payMethods.includes("PayPal")}
                onChange={updateAcceptedPaymentMethods}
              />
              <label htmlFor="payment_method_paypal" className="css-checkbox-label mb-0">
                PayPal
              </label>{" "}
              &nbsp;&nbsp;
              <input
                type="checkbox"
                name="payment_method[]"
                id="payment_method_DebitCard"
                className="css-checkbox"
                value="DebitCard"
                checked={states.payMethods.includes("DebitCard")}
                onChange={updateAcceptedPaymentMethods}
              />
              <label htmlFor="payment_method_DebitCard" className="css-checkbox-label mb-0">
                Debit Card
              </label>{" "}*/}
              {incomingList.map(methodValue => {
                const defaultMethodValue = methodValue
                if (methodValue === "Stripe") {
                  methodValue = `Add${methodValue}`
                }

                return (
                  <React.Fragment key={methodValue}>
                    &nbsp;&nbsp;
                    <input
                      type="checkbox"
                      name="payment_method[]"
                      id={`payment_method_${methodValue}`}
                      className="css-checkbox"
                      value={methodValue}
                      checked={states.payMethods.includes(methodValue)}
                      onChange={updateAcceptedPaymentMethods}
                    />
                    <label htmlFor={`payment_method_${methodValue}`} className="css-checkbox-label mb-0">
                      {defaultMethodValue}
                    </label>{" "}
                  </React.Fragment>
                )
              })}
              {/*&nbsp;&nbsp;
              <input
                type="checkbox"
                name="payment_method[]"
                id="payment_method_offline"
                className="css-checkbox"
                value="Offline / Manual"
                checked={states.payMethods.includes("Offline / Manual")}
                onChange={updateAcceptedPaymentMethods}
              />
              <label htmlFor="payment_method_offline" className="css-checkbox-label mb-0">
                Offline / Manual
              </label>*/}
              <input type="hidden" name="hiddenSubTotal" id="hiddenSubTotal" value="0" />
            </div>
          </div>
        )}

        <div className="row invoiceNotes mb-3">
          <div className="col-lg-6">
            <label htmlFor="invoiceTerms">Invoice Terms / Other Notes</label>
            <textarea
              className="form-control"
              name="invoiceTerms"
              id="invoiceTerms"
              onChange={updateNotes}
              value={states.notes}
              rows="3"
            >
              Wama: this text sticks and pre-loads based on the last-used terms
            </textarea>
          </div>
          <div className="col-lg-6">
            <label htmlFor="invoiceNote">Footer Note (Visible to Client)</label>
            <textarea
              className="form-control"
              name="invoiceNote"
              id="invoiceNote"
              rows="3"
              onChange={updateFooterNote}
              value={states.footerNote}
            ></textarea>
          </div>
        </div>
      </form>
      <CreditCard showCreditCardModal={states.showCreditCardModal} setStates={setStates} />
      <CompletePayment
        due={due}
        payorName={payor.firstName || payor.lastName || payor.username || "payor"}
        showCompletePaymentModal={showCompletePaymentModal}
        setShowCompletePaymentModal={setShowCompletePaymentModal}
      />
    </>
  )
}

export default PaymentMethod
